<template>
  <BaseSelect
    v-model="selectedCoins"
    :button-width-class="btnWidthClass"
    searchable
    show-selected
    :dropdown-width-class="dropdownWidthClass"
    :multiple="!onlyOne"
    :options="coinOptions"
    :placeholder="placeholder"
    :search-options="['label', 'ticker']"
    :display-count="displayCount"
    :clear-option="true"
    :size="size"
    @update:model-value="$emit('update', $event)"
  />
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

const $store = useStore();
const $emit = defineEmits(['update']);

const props = defineProps({
  startingCoins: Array,
  onlyOne: { type: Boolean, default: false },
  displayCount: { type: Number, default: 3 },
  placeholder: { type: String, default: 'Select Coin(s)' },
  supportedCoins: { type: Array, default: () => [] },
  showNoneOption: { type: Boolean, default: false },
  size: { type: String, default: 'md' },
  dropdownWidthClass: { type: String, default: 'w-full' },
  btnWidthClass: { type: String, default: 'w-full' }
});

// AVAILABLE COINS
const coinOptions = computed(() => {
  const allCoins = props.showNoneOption
    ? [{ uid: 0, name: 'None', ticker: '' }, ...$store.getters.coins]
    : $store.getters.coins;
  if ($store.getters.coins.length && !props.supportedCoins.length) {
    return allCoins.map(c => {
      return {
        id: c.uid,
        label: c.name,
        ticker: c.ticker,
        sub_label: c.ticker
      };
    });
  } else if ($store.getters.coins.length && props.supportedCoins.length) {
    const supportedCoinUids = props.supportedCoins.map(c => c.uid);
    return allCoins
      .filter(c => supportedCoinUids.includes(c.uid) || c.uid == 0)
      .map(c => {
        return {
          id: c.uid,
          label: c.name
        };
      });
  } else {
    return [];
  }
});

// SELECTED COINS
const selectedCoins = ref([]);
watch(coinOptions, () => {
  if (coinOptions.value?.length > 0 && selectedCoins.value.length == 0) {
    selectedCoins.value = props.startingCoins?.forEach(coin => coin.map(c => c.uid)) || [];
  }
});
watch(
  () => props.startingCoins,
  () => {
    if (props.startingCoins) {
      selectedCoins.value = props.startingCoins.map(c => c.uid);
    }
  }
);
onMounted(() => {
  if (props.startingCoins) {
    selectedCoins.value = props.startingCoins.map(c => c.uid);
  }
});
</script>
